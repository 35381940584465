import React from "react"
import Layout from "../../components/Layout"

export default function info_adm() {
  return (
    <Layout color="blue">
        <section class="banner_area">
        <div class="banner_inner d-flex align-items-center">
            <div class="overlay"></div>
            <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                <div class="banner_content text-center">
                    <h2>Informática Administrativa</h2>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section class="course_details_area section_gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 course_details_left">
                        <div class="main_image">
                            <img class="img-fluid-adap" src="/img/courses/c_info_adm.jpg" alt="" />
                        </div>
                        <div class="content_wrapper">
                            <h4 class="title">Objetivo</h4>
                            <div class="content">
                            Capacitar o aluno para atuar nas áreas administrativas de empresas de pequeno, médio e grande porte, utillizando de recursos da informática vigente nos escritórios de empresas.               
                            </div>
                            <h4 class="title">A quem se destina</h4>
                            <div class="content">
                            A todos que desejam ingressar e atuar na área de informática utilizando ferramentas simples e práticas.
                            </div>
                            <h4 class="title">Benefícios do Curso</h4>
                            <div class="content">
                            <ul>
                                <li>Palestras com especialistas e psicólogos para suportar o aluno</li>
                                <li>Certificado <b>VitalNet</b> de Conclusão de Curso</li>
                                <li>Professores capacitados e atuantes na área</li>
                                <li>Para estudantes universitários, o certificado vale como atividades complementares</li>
                                <li>Turma com números limitados e de acordo com decretos municipais e governamentais</li>
                                <li>Laboratórios e computadores esterilizados</li>
                                <li>1 Aluno por computador</li>
                                <li>Aulas práticas e <i>online</i>, de acordo com preferência</li>
                            </ul>
                            </div>

                            <h4 class="title">Grade do curso</h4>
                            <div class="content">
                                <ul class="course_list">
                                    <li class="justify-content-between d-flex">
                                        <p>Informática básica e ferramentas de escritório</p>
                                        <span class="primary-btn text-uppercase">6 meses</span>
                                    </li>
                                    <li class="justify-content-between d-flex">
                                        <p>Desenvolvimento WEB</p>
                                        <span class="primary-btn text-uppercase">3 meses</span>
                                    </li>
                                    <li class="justify-content-between d-flex">
                                        <p>Design Gráfico</p>
                                        <span class="primary-btn text-uppercase">3 meses</span>
                                    </li>
                                    <li class="justify-content-between d-flex">
                                        <p>Inglês Básico</p>
                                        <span class="primary-btn text-uppercase">6 meses</span>
                                    </li>
                                    <li class="justify-content-between d-flex">
                                        <p>Montagem e Manutenção</p>
                                        <span class="primary-btn text-uppercase">3 meses</span>
                                    </li>
                                    <li class="justify-content-between d-flex">
                                        <p>Técnicas Administrativas de Empresa</p>
                                        <span class="primary-btn text-uppercase">3 meses</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 right-contents">
                        <ul>
                            <li>
                                <a class="justify-content-between d-flex" href="#">
                                    <p>Carga Horária</p>
                                    <span class="or">192h</span>
                                </a>
                            </li>
                            <li>
                                <a class="justify-content-between d-flex" href="#">
                                    <p>Duração de aula </p>
                                    <span>50 minutos</span>
                                </a>
                            </li>
                        </ul>
                        <a href="#" class="primary-btn2 text-uppercase enroll rounded-0">Inscrever-se hoje</a>

                        <h4 class="title">Sistema de Ensino</h4>
                        <div class="content">
                            <div class="review-top row pt-40">
                                <div class="col-lg-12">
                                    <p>
                                    O curso usa métodos online e presenciais.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
  );
}